import { TextButton } from 'components/Organization/TextButton';
import resendIcon from 'images/resendInvite.svg';
import unsubscribeIcon from 'images/unsubscribe.svg';
import assignCoachIcon from 'images/assignCoach.svg';

interface SelectUsersBannerProps {
  setSelectedRows: (rows: any) => void;
  assignCoachText: string;
  resendInviteText: string;
  unsubscribeText: string;
  deselectAllText: string;
  assignCoachMethod: () => void;
  resendInviteMethod: () => void;
  unsubscribeMethod: () => void;
}

const SelectUsersBanner: React.FC<SelectUsersBannerProps> = ({
  setSelectedRows,
  assignCoachText,
  resendInviteText,
  unsubscribeText,
  deselectAllText,
  assignCoachMethod,
  resendInviteMethod,
  unsubscribeMethod
}) => {
  return (
    <div className="flex gap-6">
      <TextButton
        inverted
        size="small"
        onClick={() => assignCoachMethod()}
        className="!no-underline"
      >
        <div className="flex items-center border-b-2 border-white">
          <img src={assignCoachIcon} alt={assignCoachText} />
          <span className="ml-2 !no-underline">{assignCoachText}</span>
        </div>
      </TextButton>

      <TextButton
        inverted
        size="small"
        onClick={() => resendInviteMethod()}
        className="!no-underline"
      >
        <div className="flex items-center border-b-2 border-white">
          <img src={resendIcon} alt={resendInviteText} />
          <span className="ml-2 !no-underline">{resendInviteText}</span>
        </div>
      </TextButton>

      <TextButton
        inverted
        size="small"
        onClick={() => unsubscribeMethod()}
        className="!no-underline"
      >
        <div className="flex items-center border-b-2 border-white">
          <img src={unsubscribeIcon} alt={unsubscribeText} />
          <span className="ml-2 !no-underline">{unsubscribeText}</span>
        </div>
      </TextButton>

      <TextButton
        inverted
        size="small"
        onClick={() => setSelectedRows([])}
        className="!no-underline"
      >
        <div className="flex items-center border-b-2 border-white">
          {deselectAllText}
        </div>
      </TextButton>
    </div>
  );
};

export default SelectUsersBanner;
