const PATH = 'fe.pages.organization.invite_new_users';

const messages = {
  new: {
    key: `${PATH}.new`,
    defaultValue: 'Invite New Users'
  },
  inviteUsers: {
    key: `${PATH}.invite_users`,
    defaultValue: 'Invite Users'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email'
  },
  emailPlaceholder: {
    key: `${PATH}.email_placeholder`,
    defaultValue: 'Enter email address'
  },
  role: {
    key: `${PATH}.role`,
    defaultValue: 'Role'
  },
  rolePlaceholder: {
    key: `${PATH}.role_placeholder`,
    defaultValue: 'Select role'
  },
  errors: {
    email: {
      key: `${PATH}.errors.email`,
      defaultValue: 'You must provide a valid email address'
    },
    required: {
      key: `${PATH}.errors.required`,
      defaultValue: 'This field is required'
    },
    invited: {
      key: `${PATH}.errors.invited`,
      defaultValue: 'User {{name}} ({{role}}) already invited.'
    },
    subscribed: {
      key: `${PATH}.errors.subscribed`,
      defaultValue: 'User {{name}} ({{role}}) is already subscribed.'
    },
    noInvitation: {
      key: `${PATH}.errors.no_invitation`,
      defaultValue: 'User {{name}} ({{role}}) does not have an invitation.'
    },
    error: {
      key: `${PATH}.errors.error`,
      defaultValue: 'Error checking subscription. Please try again later.'
    }
  },
  back: {
    key: `${PATH}.back`,
    defaultValue: 'Back to Learning Resources'
  },
  addAnotherUser: {
    key: `${PATH}.add_another_user`,
    defaultValue: 'Invite Another User'
  },
  deleteForm: {
    key: `${PATH}.delete_form`,
    defaultValue: 'Delete Form'
  },
  upload: {
    key: `${PATH}.upload`,
    defaultValue: 'Upload via CSV'
  },
  userInvited: {
    key: `${PATH}.user_invited`,
    defaultValue: '{{count}} invite(s) sent'
  },
  clearForm: {
    key: `${PATH}.clear_form`,
    defaultValue: 'Clear Form'
  }
};

export default messages;
