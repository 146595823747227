import useCurrentUser from './useCurrentUser';

type ACTIVE_FEATURE_FLAGS =
  | 'authorized_2008_age_levels'
  | 'members_upload_v2'
  | 'enrollment_transfer'
  | 'hierarchy_management'
  | 'subscriptions';

interface UseFeatureFlags {
  isFeatureFlagEnabled: (featureFlagName: ACTIVE_FEATURE_FLAGS) => boolean;
}

function useFeatureFlags(): UseFeatureFlags {
  const userData = useCurrentUser();

  function isFeatureFlagEnabled(featureFlagName: ACTIVE_FEATURE_FLAGS) {
    return Boolean(userData?.feature_flags?.includes(featureFlagName));
  }

  return { isFeatureFlagEnabled };
}

export default useFeatureFlags;
