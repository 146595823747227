interface StatusBadgeProps {
  iconType: 'accepted' | 'invited' | 'not_invited';
  text: string;
}

function StatusBadge({ iconType, text }: StatusBadgeProps) {
  const bgColor = getBGColor();

  function getBGColor() {
    switch (iconType) {
      case 'accepted':
        return 'bg-lime-200';
      case 'invited':
        return 'bg-gold-200';
      default:
        return 'bg-orange-200';
    }
  }

  return (
    <div
      className={`inline-flex items-center space-x-2 px-4 py-2 text-black text-sm rounded-full font-semibold ${bgColor}`}
    >
      {iconType === 'accepted' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 13l4 4L19 7"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 8l7.89 5.26c.38.25.84.25 1.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      )}
      <span>{text}</span>
    </div>
  );
}

export default StatusBadge;
