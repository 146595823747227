import clsx from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState, useRef, useEffect, KeyboardEvent } from 'react';
import { Label, Field } from '@headlessui/react';
import { useGetAccountMembers } from 'actions/customers';

interface Customer {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

interface EmailAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onSelect?: (customer: Customer) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

function EmailAutocomplete(props: EmailAutocompleteProps) {
  const {
    value,
    onChange,
    placeholder = i18n.ft(messages.placeholder),
    onSelect,
    onBlur,
    onFocus
  } = props;
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useGetAccountMembers({ query: value });

  const handleSelect = (customer: Customer) => {
    onChange(customer.email);
    setDropdownVisible(false);
    setSelectedIndex(-1);
    onSelect?.(customer);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isDropdownVisible || data?.users?.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prevIndex => {
          const nextIndex = (prevIndex + 1) % data.users.length;
          scrollIntoView(nextIndex);
          return nextIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prevIndex => {
          const prev = prevIndex <= 0 ? data.users.length - 1 : prevIndex - 1;
          scrollIntoView(prev);
          return prev;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0) {
          handleSelect(data.users[selectedIndex]);
        }
        break;
      case 'Escape':
        setDropdownVisible(false);
        break;
    }
  };

  const scrollIntoView = (index: number) => {
    if (dropdownRef.current) {
      const item = dropdownRef.current.children[index] as HTMLElement;
      item?.scrollIntoView({ block: 'nearest' });
    }
  };

  useEffect(() => {
    if (!isDropdownVisible) setSelectedIndex(-1);
  }, [isDropdownVisible]);

  return (
    <div className="w-full">
      <Field className="flex flex-col gap-1 font-sans">
        <Label className="font-semibold text-base text-black">
          {i18n.ft(messages.email)}
        </Label>
        <input
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          onFocus={() => {
            setDropdownVisible(true);
            onFocus?.();
          }}
          onBlur={() => {
            setTimeout(() => setDropdownVisible(false), 200);
            onBlur?.();
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            setDropdownVisible(true);
            handleKeyDown(e);
          }}
          className="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-action border-[#889EBB] text-black font-sans text-base"
        />
      </Field>
      {isDropdownVisible && (
        <div
          ref={dropdownRef}
          className="z-10 bg-white border-2 rounded-md w-full shadow-lg max-h-48 overflow-auto border-action"
        >
          {isLoading ? (
            <div className="p-4 text-gray-500">{i18n.ft(messages.loading)}</div>
          ) : data?.users?.length > 0 ? (
            data?.users?.map((customer: Customer, index: number) => (
              <div
                key={index}
                onClick={() => {
                  handleSelect(customer);
                }}
                className={clsx('px-4 py-2 hover:bg-blue-100 cursor-pointer', {
                  'bg-blue-100': index === selectedIndex
                })}
              >
                <div className="font-semibold">{customer.email}</div>
                <div className="text-sm font-normal">
                  {customer.first_name + ' ' + customer.last_name}
                </div>
              </div>
            ))
          ) : (
            <div className="p-4 text-gray-500">
              {i18n.ft(messages.noResults)}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EmailAutocomplete;
