const PATH = 'fe.pages.organization';

const messages = {
  manageOrganization: {
    key: `${PATH}.manage_organization`,
    defaultValue: 'Manage Organization'
  },
  selectAccount: {
    key: `${PATH}.select_account`,
    defaultValue: 'Please select an account to continue'
  },
  members: {
    key: `${PATH}.members_title`,
    defaultValue: 'Members'
  },
  hierarchy: {
    key: `${PATH}.hierarchy_title`,
    defaultValue: 'Hierarchy'
  },
  subscriptions: {
    key: `${PATH}.subscriptions`,
    defaultValue: 'Subscriptions'
  }
};

export default messages;
