import { Routes, Route } from 'react-router-dom';
import OrganizationInviteUsers from 'pages/Organization/InviteNewUsers/InviteUsers';
import OrganizationLearningResources from 'pages/Organization/LearningResources';

function SubscriptionsSwitch() {
  return (
    <Routes>
      <Route
        path="/learning-resources"
        element={<OrganizationLearningResources />}
      />
      <Route path="/invite-users" element={<OrganizationInviteUsers />} />
    </Routes>
  );
}

export default SubscriptionsSwitch;
