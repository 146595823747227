import { clsx } from 'clsx';

interface PrimaryButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Font awesome icon class names. */
  icon?: string;

  /** Button size. Defaults to `normal`. */
  size?: 'small' | 'normal';
}

export function PrimaryButton({
  icon,
  className,
  children,
  size = 'normal',
  ...props
}: PrimaryButtonProps) {
  const sizes = {
    small: 'text-sm px-3.5 py-1.5',
    normal: 'text-base px-4 py-2'
  };

  const classes = clsx(
    'font-sans font-semibold text-white bg-action rounded',
    // hover state
    'hover:bg-action',
    // focus state
    'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-action',
    // disabled state
    'disabled:bg-disable disabled:text-[#AFBFD2]',
    // size classes
    sizes[size],
    // additional classes
    className
  );

  return (
    <button className={classes} {...props}>
      {icon && (
        <span className="inline-flex items-center justify-center mr-2 w-4 h-4">
          <i className={icon} />
        </span>
      )}
      {children}
    </button>
  );
}
