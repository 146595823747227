import i18n from 'helpers/i18n';
import messages from './messages';

interface LeaveModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLeave: () => void;
}

const LeaveModal = ({ isOpen, onClose, onLeave }: LeaveModalProps) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white shadow-lg rounded-lg p-4 w-64 h-auto">
        <h2 className="font-montserrat font-bold text-xl leading-7 mb-6">
          {i18n.ft(messages.title)}
        </h2>
        <p className="font-montserrat font-normal text-base leading-6 mb-8 mt-4">
          {i18n.ft(messages.content)}
        </p>
        <div className="flex flex-col gap-4">
          <button
            className="bg-action text-white font-semibold text-base leading-6 w-full h-11 rounded-md py-3 px-4"
            onClick={onLeave}
          >
            {i18n.ft(messages.yes)}
          </button>
          <button
            className="font-semibold text-sm leading-6 w-full rounded-md border-0 py-3 px-4 text-[#165F70]"
            onClick={onClose}
          >
            {i18n.ft(messages.no)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaveModal;
