import debounce from 'debounce';
import i18n from 'helpers/i18n';
import messages from './messages';
import PageLoader from 'components/PageLoader';
import { useNavigate, Link } from 'react-router-dom';
import { roleToText } from 'helpers/role';
import { Table } from 'components/Organization/Table';
import { Pagination } from 'components/Organization/Pagination';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import {
  useGetLearningResourceSubscription,
  useGetLearningResourceUsers
} from 'actions/learningResources';
import RouteHelpers from 'helpers/routes';
import { useRef, useState } from 'react';
import RolePopup from 'components/Organization/RolePopup';
import { composeUser, formatDate } from '../Members/utils';
import { Checkbox } from '@headlessui/react';
import StatusBadge from 'components/Organization/StatusBadge';
import useCurrentUser from 'hooks/useCurrentUser';
import SelectUsersBanner from 'components/Organization/SelectUsersBanner';

function LearningResources() {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isInviteStatusPopupOpen, setIsInviteStatusPopupOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const filterRoleButtonRef = useRef<HTMLButtonElement>(null);
  const filterInviteStatusButtonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const accountId = currentUser.current_account_id ?? 0;
  const { data: subscription } = useGetLearningResourceSubscription(accountId);

  const PER_PAGE = 20;
  const learningResourceRoles = [
    { id: 'lr_admin', name: 'Admin' },
    { id: 'lr_coach', name: 'Coach' },
    { id: 'lr_educator', name: 'Educator' }
  ];

  const statusList = [
    { id: 'accepted', name: 'Accepted' },
    { id: 'invited', name: 'Invited' },
    { id: 'no_invitation', name: 'No Invitation' }
  ];

  const handleInviteStatusFilterButtonClick = () => {
    setIsInviteStatusPopupOpen(!isInviteStatusPopupOpen);
  };

  const handleRoleFilterButtonClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleRolePopupClose = () => {
    setIsPopupOpen(false);
    filterRoleButtonRef.current?.focus();
  };

  const handleInviteStatusPopupClose = () => {
    setIsInviteStatusPopupOpen(false);
    filterInviteStatusButtonRef.current?.focus();
  };

  const handleRolesChange = (newRoles: string[]) => {
    setSelectedRoles(newRoles);
  };

  const handleStatusChange = (newRoles: string[]) => {
    setSelectedStatuses(newRoles);
  };

  const clearRoles = () => {
    setSelectedRoles([]);
  };

  const clearStatuses = () => {
    setSelectedStatuses([]);
  };

  const handleRowSelect = (userId: number) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(userId)
        ? prevSelectedRows.filter(id => id !== userId)
        : [...prevSelectedRows, userId]
    );
  };

  const handleHeaderSelect = () => {
    if (!data || !data.membership_trackers) return;

    if (selectedRows.length <= 0) {
      const allUserIdsOnPage = data.membership_trackers.map(
        (membership: any) => membership.id
      );
      setSelectedRows(allUserIdsOnPage);
    } else {
      setSelectedRows([]);
    }
  };

  const sortMapping: { [key: string]: string } = {
    name: 'name',
    email: 'email',
    role: 'role',
    invite_status: 'invite_status',
    invited_on: 'invited_on'
  };

  const baseParams = {
    query: searchQuery,
    sort_by: sortBy && sortBy in sortMapping ? sortMapping[sortBy] : sortBy,
    sort_dir: sortDir,
    role: selectedRoles,
    status: selectedStatuses
  };

  const tableParams = {
    ...baseParams,
    per_page: PER_PAGE,
    page
  };

  const filteredTableParams = Object.fromEntries(
    Object.entries(tableParams).filter(([_, value]) => value != null)
  );

  const { data, isLoading } = useGetLearningResourceUsers(
    // Get the right subscription id once the LRN-1836 is done
    (subscription?.length ?? 0) > 0 ? subscription?.[0]?.id ?? 0 : 0,
    filteredTableParams
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  const handleSort = (header: string) => {
    if (header === sortBy && sortDir === 'asc') {
      setSortDir('desc');
    } else if (header === sortBy && sortDir === 'desc') {
      setSortBy(null);
      setSortDir(null);
    } else {
      setSortBy(header);
      setSortDir('asc');
    }
  };

  const getLastPage = () => {
    if (page === 1) return PER_PAGE;

    const totalPages = data?.pagination.total_pages;
    if (page === totalPages) {
      return (data?.membership_trackers.length || 0) + PER_PAGE * (page - 1);
    }

    return page * PER_PAGE;
  };

  const tableHeaders = [
    {
      content: (
        <Checkbox
          checked={selectedRows.length > 0}
          onChange={handleHeaderSelect}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-minus" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      accessor: 'checkbox'
    },
    {
      content: i18n.ft(messages.nameAndEmail),
      accessor: 'name',
      sortable: true
    },
    {
      content: i18n.ft(messages.role),
      accessor: 'role',
      filter: (
        <div className="flex gap-2">
          <button
            ref={filterRoleButtonRef}
            onClick={handleRoleFilterButtonClick}
            className={`font-bold ${
              isPopupOpen ? 'text-action' : 'text-black'
            }`}
            aria-haspopup="true"
            aria-expanded={isPopupOpen}
            aria-label={i18n.ft(messages.filterRoles)}
          >
            <i className="fa-solid fa-bars-filter fa-sm"></i>
          </button>
          {isPopupOpen && (
            <RolePopup
              accountId={-1}
              isOpen={isPopupOpen}
              onClose={handleRolePopupClose}
              onChange={handleRolesChange}
              selectedValues={selectedRoles}
              roleList={learningResourceRoles}
            />
          )}
          {selectedRoles.length > 0 && (
            <div>
              <button
                onClick={clearRoles}
                className="text-action cursor-pointer"
                aria-label={i18n.ft(messages.clearRoles)}
              >
                <i className="fa-regular fa-circle-xmark" />
              </button>
            </div>
          )}
        </div>
      )
    },
    {
      content: i18n.ft(messages.inviteStatus),
      accessor: 'invite_status',
      className: 'max-w-md',
      filter: (
        <div className="flex gap-2">
          <button
            ref={filterInviteStatusButtonRef}
            onClick={handleInviteStatusFilterButtonClick}
            className={`font-bold ${
              isInviteStatusPopupOpen ? 'text-action' : 'text-black'
            }`}
            aria-haspopup="true"
            aria-expanded={isInviteStatusPopupOpen}
            aria-label={i18n.ft(messages.filterStatus)}
          >
            <i className="fa-solid fa-bars-filter fa-sm"></i>
          </button>
          {isInviteStatusPopupOpen && (
            <RolePopup
              accountId={-1}
              isOpen={isInviteStatusPopupOpen}
              onClose={handleInviteStatusPopupClose}
              onChange={handleStatusChange}
              selectedValues={selectedStatuses}
              roleList={statusList}
            />
          )}
          {selectedStatuses.length > 0 && (
            <div>
              <button
                onClick={clearStatuses}
                className="text-action cursor-pointer"
                aria-label={i18n.ft(messages.clearStatus)}
              >
                <i className="fa-regular fa-circle-xmark" />
              </button>
            </div>
          )}
        </div>
      )
    },
    {
      content: i18n.ft(messages.invited),
      accessor: 'invited_on',
      sortable: true
    },
    {
      content: i18n.ft(messages.coach),
      accessor: 'coach',
      sortable: false
    }
  ];

  const tableData = data?.membership_trackers.map((membership: any) => {
    return {
      selected: selectedRows.includes(membership.id),
      checkbox: (
        <Checkbox
          checked={selectedRows.includes(membership.id)}
          onChange={() => handleRowSelect(membership.id)}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-check" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      name: composeUser(membership.id, membership.name, membership.email),
      email: membership.email,
      role: roleToText(membership.role),
      last_login: '',
      invited_on: formatDate(membership.invited_on ?? undefined),
      invite_status: (
        <StatusBadge
          iconType={membership.invite_status}
          text={getStatusText(membership.invite_status)}
        />
      ),
      coach:
        membership.role === 'lr_coach' ? (
          <Link
            to={'/organization/subscriptions'}
            className="text-black font-bold underline"
          >
            {i18n.ft(messages.view)}
          </Link>
        ) : (
          <span>--</span>
        )
    };
  });

  const membersCount = data?.pagination.total_pages;

  function getStatusText(status: string) {
    switch (status) {
      case 'accepted':
        return i18n.ft(messages.acceptedStatus);
      case 'invited':
        return i18n.ft(messages.invitedStatus);
      default:
        return i18n.ft(messages.notInvitedStatus);
    }
  }

  return (
    <div className="my-8 relative">
      <div className="bg-[#0A9CCA] -mx-6 xl:-mx-14 left-0 right-0 h-52 rounded-b-2xl" />
      <div className="-mt-44 p-6 pb-12 bg-white rounded-2xl">
        <div className="flex flex-col">
          <div>
            <h2 className="font-sans font-bold text-3xl">
              {i18n.ft(messages.learningResourcesUsers)}
            </h2>
          </div>
          <div className="mt-1 flex justify-between items-center">
            <a
              className="flex items-center gap-2 font-semibold text-sm text-action"
              href={RouteHelpers.getPath('organization-unsubscribed-users')}
            >
              <i className="fa-solid fa-download" />
              <span>{i18n.ft(messages.unsubscribedUsers)}</span>
            </a>

            <div className="flex gap-6">
              <input
                type="search"
                className="border rounded-md max-w-94 min-w-80 h-10 p-2 placeholder:font-sans"
                onChange={debounce(handleSearch, 300)}
                placeholder={i18n.ft(messages.searchPlaceholder)}
                aria-label={i18n.ft(messages.searchPlaceholder)}
              />

              <PrimaryButton
                icon="fa-solid fa-plus"
                onClick={() =>
                  navigate(
                    RouteHelpers.getPath('organization-subscriptions-invite')
                  )
                }
                disabled={!data}
              >
                {i18n.ft(messages.invite)}
              </PrimaryButton>
            </div>
          </div>
        </div>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {tableData && (
              <>
                <div className="text-[#3C3F42] mt-6 mb-1.5 text-sm">
                  {i18n.ft(messages.usersCount, {
                    initial: page === 1 ? 1 : (page - 1) * PER_PAGE + 1,
                    end: getLastPage(),
                    total: membersCount
                  })}
                </div>

                <Table
                  headers={tableHeaders}
                  data={tableData}
                  sortBy={sortBy}
                  sortDir={sortDir}
                  onSort={handleSort}
                  actions={
                    selectedRows.length > 0 ? (
                      <SelectUsersBanner
                        setSelectedRows={setSelectedRows}
                        assignCoachText={i18n.ft(messages.assignCoach)}
                        resendInviteText={i18n.ft(messages.resendInvite, {
                          count: selectedRows.length
                        })}
                        unsubscribeText={i18n.ft(messages.unsubscribe, {
                          count: selectedRows.length
                        })}
                        deselectAllText={i18n.ft(messages.deselectAll)}
                        assignCoachMethod={() => {}}
                        resendInviteMethod={() => {}}
                        unsubscribeMethod={() => {}}
                      />
                    ) : null
                  }
                />

                <div className="mt-6 flex justify-center">
                  <Pagination
                    page={page}
                    onPageChange={setPage}
                    total={data?.pagination.total_pages ?? 0}
                    label={i18n.ft(messages.totalUsers, {
                      count: membersCount
                    })}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default LearningResources;
